import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { Link, useNavigate } from "react-router-dom";
// AXIOS
import axios from 'axios';
// CONFIG
// import conf from "../../config.json";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// TOLTIP
import { Tooltip } from 'react-tooltip'
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function toggleMenu() {
  document.getElementById('menu').classList.toggle('active');
}

function closeMenu() {
  document.getElementById('menu').classList.remove('active');
}

function openSubNav(e) {
  e.preventDefault();
  let submenu = e.target.parentNode.querySelector('ul');
  if (submenu)
    submenu.classList.toggle('visible');
  return false;
}

function Header() {
  // AUTH, STATE, NAVIGATION
  const { authobj, lingue, lang, setLanguage, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  let navigate = useNavigate();
  // DATA
  const [numAlertsUnread, setNumAlertsUnread] = useState([]);
  const italianMonths = ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"];

  useEffect(() => {
    closeMenu();
    setGlobalRefresh(null);
    axios.post(window.SERVER_URL+'alerts/get_unread_count', { }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setNumAlertsUnread(res.data);
    }).catch(function(error) {
      console.log(error);
    });
  }, [globalRefresh]);

  return (
    <>
      <Container id='header'>
        <Row>
          <Col>
            <span style={{'display':'inline-block','paddingTop':'12px'}}>
              <img src={`${process.env.PUBLIC_URL}/gfx/Iconly-Light-Paper.png`} style={{'display':'inline-block','marginRight':'10px'}} />
              {`${(new Date()).getDate()} ${italianMonths[(new Date()).getMonth()]} ${(new Date()).getFullYear()}`}
            </span>
          </Col>
          <Col id='headicons_wrapper'>
            <div className='headicon' onClick={toggleMenu}><DynamicIcon iconName='GiHamburgerMenu' /></div>
            <div className='headicon' onClick={() => { navigate('/alerts') }}>
              <img src={`${process.env.PUBLIC_URL}/gfx/Raggruppa 163.png`} className='alerts_header_btn' />
              <span>{ numAlertsUnread }</span>
              <Tooltip anchorSelect=".alerts_header_btn" place="bottom" variant="info">Hai <b>{ numAlertsUnread }</b> alert da leggere</Tooltip>
            </div>
            <div className='headicon'>
              <img src={`${process.env.PUBLIC_URL}/gfx/Raggruppa 162.png`} className='messages_header_btn' />
              <span>0</span>
            </div>
            <div id="search_wrapper"><input type="text" name="search" placeholder="Cerca pratiche, documenti e utenti" /></div>
          </Col>
        </Row>
      </Container>
      <Container id='menu'>
        <Row>
          <div className="sx" onClick={toggleMenu}>
            <DynamicIcon iconName='GiHamburgerMenu' />
          </div>
          <div className="dx">
            <img alt="SPF" id='logo' src={`${process.env.PUBLIC_URL}/gfx/logo_new.png`} onClick={() => { setGlobalRefresh(1); navigate('/dashboard') }} />
            
            <div id="nav_wrapper">
              <nav>
                <ul>
                  { authobj.permissions.includes('dashboard.view') ?
                    <li><Link to="/dashboard" onClick={closeMenu}><img src={`${process.env.PUBLIC_URL}/gfx/Iconly-Light-Category.png`} /> Dashboard</Link></li>
                  : '' }
                  { authobj.permissions.includes('users.list') ?
                    <li><Link to="#" onClick={openSubNav}><img src={`${process.env.PUBLIC_URL}/gfx/SPF_AssetsIcone-03.png`} /> Utenti, ruoli e permessi <DynamicIcon iconName='IoMdArrowDropdown' className="last" /></Link>
                      <ul>
                        <li><Link to="/users" onClick={closeMenu}><DynamicIcon iconName='ImUsers' /> Gestione utenti</Link></li>
                        <li><Link to="/roles" onClick={closeMenu}><DynamicIcon iconName='FaUnlockAlt' /> Ruoli e permessi</Link></li>
                      </ul>
                    </li>
                  : '' }
                  { authobj.permissions.includes('evaluations.list') ?
                    <li><Link to="#" onClick={openSubNav}><img src={`${process.env.PUBLIC_URL}/gfx/Tracciato 262.png`} /> Pratiche <DynamicIcon iconName='IoMdArrowDropdown' className="last" /></Link>
                      <ul>
                        <li><Link to="/evaluations" onClick={closeMenu}><DynamicIcon iconName='FaFolderOpen' /> Gestione pratiche</Link></li>
                        { authobj.permissions.includes('evaluations.import') ?
                          <li><Link to="/import/evaluations" onClick={closeMenu}><DynamicIcon iconName='FaFileImport' /> Import massivo</Link></li>
                        : '' }
                      </ul>
                    </li>
                  : '' }
                  { authobj.permissions.includes('customers.list') ?
                    <li><Link to="/customers"><img src={`${process.env.PUBLIC_URL}/gfx/SPF_AssetsIcone_Tavola disegno 1.png`} /> Clienti</Link></li>
                  : '' }
                  { authobj.permissions.includes('technicians.list') ?
                    <li><Link to="/technicians"><img src={`${process.env.PUBLIC_URL}/gfx/SPF_AssetsIcone-08.png`} /> Tecnici</Link></li>
                  : '' }
                  { authobj.permissions.includes('billings.list') ?
                    <li><Link to="#" onClick={openSubNav}><img src={`${process.env.PUBLIC_URL}/gfx/SPF_AssetsIcone-07.png`} /> Fatture e contratti <DynamicIcon iconName='IoMdArrowDropdown' className="last" /></Link>
                      <ul>
                        { authobj.permissions.includes('billings.export') ?
                          <li><Link to="/export/invoiceable" onClick={closeMenu}><DynamicIcon iconName='FaFileExport' /> Export fatturabili</Link></li>
                        : '' }
                      </ul>
                    </li>
                  : '' }
                  { authobj.permissions.includes('reports.list') ?
                    <li><Link to="#"><img src={`${process.env.PUBLIC_URL}/gfx/SPF_AssetsIcone-06.png`} /> Report</Link></li>
                  : '' }
                </ul>
              </nav>

              <hr />
              <div style={{'textAlign':'center'}}>
                <h3>HAI BISOGNO DI AIUTO?</h3>
                <Button style={{'padding':'10px 30px'}}>Scrivi al supporto</Button>
              </div>
              <br /><hr />

              <div id="info_connected" className='profile_link' style={{'position':'relative'}}>
                <div onClick={() => { if (authobj.user.role.slug == 'tec') navigate('/technicians/add', { state: { technician_id: authobj.user.id } }); } }>
                  <DynamicIcon iconName='FaUserCircle' id="user_icon" />
                  <p className='role_label sidebar'>{ authobj.role }</p>
                  <div style={{'textAlign':'center'}}>
                    <b>{ authobj.name }</b>
                    <p style={{'marginTop':'5px'}}>{ authobj.email }</p>
                  </div>
                </div>
                {/* { authobj.user.role.slug == 'tec' ?
                  <Tooltip anchorSelect=".profile_link" place="top" variant="info">Vai al tuo profilo</Tooltip>
                : '' } */}
                <Link to="#" className="logout" onClick={signout}><DynamicIcon iconName='ImExit' /> Logout</Link>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Header;