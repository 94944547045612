import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation, redirect } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm, useFieldArray } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT FILE UPLOADER
import { FileUploader } from "react-drag-drop-files";
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function EvaluationsAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, control, handleSubmit, trigger, getValues, setValue, reset, formState: { errors } } = useForm();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "assets"
  });
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [evaluation, setEvaluation] = useState(null);
  const [step, setStep] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [selectCustomers, setSelectCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectBillingCustomers, setSelectBillingCustomers] = useState([]);
  const [selectedBillingCustomer, setSelectedBillingCustomer] = useState(null);
  const [technicians, setTechnicians] = useState([]);
  const [selectTechnicians, setSelectTechnicians] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [evaluationTypes, setEvaluationTypes] = useState([]);
  const [lastStep, setLastStep] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [parcelTypes, setParcelTypes] = useState([]);
  const [registryCategories, setRegistryCategories] = useState([]);
  // FILE UPLOADER
  const [file, setFile] = useState(null);
  const [evaluationFiles, setEvaluationFiles] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    // Carico i clienti
    axios.post(window.SERVER_URL+'customers/list', { params: { 'light': true } }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setCustomers(res.data.customers);
      let select_options = [];
      res.data.customers.map((c) => {
        select_options.push({ value: c.id, label: c.etichetta });
      });
      setSelectCustomers(select_options);
      setSelectBillingCustomers(select_options);

      // Carico i tecnici
      axios.post(window.SERVER_URL+'technicians/list', { params: { 'light': true } }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        setTechnicians(res.data.technicians);
        let select_options = [];
        res.data.technicians.map((t) => {
          select_options.push({ value: t.id, label: t.nome+' '+t.cognome });
        });
        setSelectTechnicians(select_options);

        // Carico le tipologie di pratiche
        axios.post(window.SERVER_URL+'evaluation_types/list', { }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setEvaluationTypes(res.data.evaluation_types);

          // Carico le province
          axios.post(window.SERVER_URL+'provinces/list', { }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            setProvinces(res.data.provinces);

            // Carico le tipologie di particelle
            axios.post(window.SERVER_URL+'parcel_types/list', { }, { headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }}).then(res => {
              setParcelTypes(res.data.parcel_types);

              // Carico le categorie catastali
              axios.post(window.SERVER_URL+'registry_categories/list', { }, { headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }}).then(res => {
                setRegistryCategories(res.data.registry_categories);

                // Se sto modificando una pratica esistente, carico le informazioni relative
                if (state && state.evaluation_id) {
                  axios.post(window.SERVER_URL+'evaluations/get', { id: state.evaluation_id }, { headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                  }}).then(res => {
                    console.log(res.data);
                    setEvaluation(res.data.evaluation);

                    // Carico i file dell'attività
                    axios.post(window.SERVER_URL+'evaluations/get_files', { evaluation_id: state.evaluation_id }, {
                      headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer '+authobj.token
                      }
                    }).then(resf => {
                      console.log(resf.data);
                      setEvaluationFiles(resf.data.files);
                    
                      // Mi setto le select
                      if (res.data.evaluation.user_id)
                        setSelectedCustomer({ value: res.data.evaluation.user_id, label: res.data.evaluation.user.etichetta });
                      if (res.data.evaluation.billing_user_id)
                        setSelectedBillingCustomer({ value: res.data.evaluation.billing_user_id, label: res.data.evaluation.billing_user.etichetta });
                      if (res.data.evaluation.technician_id)
                        setSelectedTechnician({ value: res.data.evaluation.technician_id, label: res.data.evaluation.technician.nome+' '+ res.data.evaluation.technician.cognome});

                      // Setto i campi della form
                      let xfields = {};
                      for (const [key, val] of Object.entries(res.data.evaluation))
                        if (typeof val !== 'object')
                          xfields['evaluation['+key+']'] = val;
                      // Setto i campi della form di fatturazione
                      if (res.data.evaluation.billing) {
                        for (const [key, val] of Object.entries(res.data.evaluation.billing))
                          if (typeof val !== 'object')
                            xfields['billing['+key+']'] = val;
                      }
                      reset(xfields);
                      // Setto i campi dei beni (assets)
                      if (res.data.evaluation && res.data.evaluation.assets && res.data.evaluation.assets.length) {
                        res.data.evaluation.assets.map((item, index) => {
                          insert(index, item);
                        });
                      }

                      setWait(false);
                    });
                  });
                } else {
                  setWait(false);
                }
              });
            });
          });
        });
      });
    });
  }, [reset]);

  const addAsset = () => {
    append({
      indirizzo: null,
      civico: null,
      localita: null,
      comune: null,
      province_id: null,
      cap: null,
      stato: null,
      parcel_type_id: null,
      registry_category_id: null,
      // cat_tav: null,
      sezione: null,
      foglio: null,
      mappale: null,
      subalterno: null,
      note: null,
    });
  }

  const handleFileUpload = (files) => {
    console.log(files);
    if (evaluation.id) {
      setWait(true);

      // Carico i files
      const formData = new FormData();
      var readed_files = [];
      // Ciclo sui vari file
      const promises = [];
      Array.from(files).forEach(function(f, idx) {
        promises.push(new Promise((resolve, reject) => {
          var fileReader = new FileReader();
          fileReader.onload = function(evt) {
            let readed = {
              'metadata': {
                'name': f.name,
                'type': f.type,
                'size': f.size
              },
              'content': evt.target.result
            };
            readed_files.push(readed);
            resolve(readed);
          };
          fileReader.onerror = reject;
          fileReader.readAsDataURL(f);
        }));
      });

      Promise.all(promises).then((results) => {
        formData.append('uploads', JSON.stringify(results));
        formData.append('evaluation_id', evaluation.id);
        axios.post(window.SERVER_URL+'evaluations/upload', formData, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token,
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          if (res.data.success == true) {
            setWait(false);
            Swal.fire({
              icon: 'success',
              title: 'Caricamento completato',
              html: '<b>'+res.data.log.length+' file</b> sono stati caricati correttamente'
            }).then((result) => {
              if (result.isConfirmed) {
                // Recupero la lista aggiornata dei files
                setWait(true);
                axios.post(window.SERVER_URL+'evaluations/get_files', { evaluation_id: evaluation.id }, {
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                  }
                }).then(reslist => {
                  console.log(reslist.data);
                  setEvaluationFiles(reslist.data.files);
                  setWait(false);
                });
              }
            });
          } else {
            let log = "";
            res.data.log.forEach(function(row, idx) {
              log += row.name+': '+(row.result ? 'caricato' : 'fallito')+'<br>'
            });
            Swal.fire({
              icon: res.data.log.length ? 'warning' : 'error',
              title: res.data.log.length ? 'Uno o più caricamenti sono falliti' : 'Caricamento dei file fallito',
              html: log
            });
          }
        });
      });

      setWait(false);
    }
  };

  const handleDeleteFile = (id_file) => {
    Swal.fire({
      title: '<strong>Sei sicuro di voler cancellare questo file?</strong>',
      html: "<p>L'operazione non è reversibile</p>",
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'ELIMINA',
      denyButtonText: 'ANNULLA',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'evaluations/delete_file', { id: id_file }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            setEvaluationFiles(evaluationFiles.filter(f => f.id !== id_file));
            Swal.fire({
              icon: 'success',
              title: 'File cancellato',
              // text: 'Il movimento è stato cancellato',
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: 'Si è verificato un problema nella cancellazione'
            });
          }
        });
      }
    });
  }
  
  // const valid = await trigger(["evaluation[id_commessa]", "evaluation[evaluation_type_id]"]);

  const goToStep = async (num) => {
    setWait(true);
    console.log("VADO ALLO STEP "+num);
    // Validazione dei dati a seconda dello step
    let valid = true;
    const values = getValues();
    switch (step) {
      // VALIDAZIONE STEP 1
      case 1:
        console.log("VALIDAZIONE STEP 1");
        if (!selectedCustomer) {
          valid = false;
          setWait(false);
          Swal.fire({
            icon: 'warning',
            title: 'Informazioni mancanti',
            text: "Non hai selezionato un cliente per questa pratica"
          });
        }
        if (!values.evaluation.evaluation_type_id) {
          valid = false;
          setWait(false);
          Swal.fire({
            icon: 'warning',
            title: 'Informazioni mancanti',
            text: "Non hai selezionato una tipologia pratica"
          });
        }
        if (valid)
          handleSubmit(onSubmit)();
      break;
      // VALIDAZIONE STEP 2
      case 2:
        setWait(false);
      break;
      // VALIDAZIONE STEP 3
      case 3:
        console.log("VALIDAZIONE STEP 3");
        console.log(values);
        if (values.assets) {
          values.assets.map((ass, i) => {
            if (!ass.indirizzo || !ass.civico || !ass.comune || !ass.province_id || !ass.cap || !ass.stato || !ass.parcel_type_id || !ass.registry_category_id || !ass.foglio || !ass.mappale || !ass.subalterno) {
              valid = false;
              setWait(false);
              Swal.fire({
                icon: 'warning',
                title: 'Informazioni mancanti',
                text: "Non hai inserito tutti i dati obbligatori per il bene n."+(i+1)
              });
            }
          });
        }
        if (valid)
          handleSubmit(onSubmit)();
      break;
      // VALIDAZIONE STEP 4
      case 4:
        console.log("VALIDAZIONE STEP 4");
        console.log(values);
        if (!values.evaluation.sopralluogo_referente) {
          valid = false;
          setWait(false);
          Swal.fire({
            icon: 'warning',
            title: 'Informazioni mancanti',
            text: "Non hai indicato il referente per il sopralluogo"
          });
        }
        if (!values.evaluation.sopralluogo_telefono_1) {
          valid = false;
          setWait(false);
          Swal.fire({
            icon: 'warning',
            title: 'Informazioni mancanti',
            text: "Non hai indicato il telefono del referente"
          });
        }
        if (valid)
          handleSubmit(onSubmit)();
      break;
      // VALIDAZIONE STEP 5
      case 5:
        console.log("VALIDAZIONE STEP 5");
        console.log(values);
        if (!selectedBillingCustomer && (!values.billing.ragione_sociale || !values.billing.partita_iva || !values.billing.codice_fiscale || !values.billing.comune || !values.billing.cap || !values.billing.indirizzo || !values.billing.civico || !values.billing.stato)) {
          valid = false;
          setWait(false);
          Swal.fire({
            icon: 'warning',
            title: 'Informazioni di fatturazione errate',
            text: "Devi selezionare un cliente o fornire tutti i campi obbligatori per la fatturazione"
          });
        }
        if (valid)
          handleSubmit(onSubmit)();
      break;
      // VALIDAZIONE STEP 6
      case 6:
        // Aggiorno la lista dei tecnici in base all'ID perizia, in modo da ottenere solo i tecnici con la competenza geografica corretta
        axios.post(window.SERVER_URL+'technicians/list', { params: { light: true, evaluation_id: evaluation.id } }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          setTechnicians(res.data.technicians);
          let select_options = [];
          res.data.technicians.map((t) => {
            select_options.push({ value: t.id, label: t.nome+' '+t.cognome });
          });
          setSelectTechnicians(select_options);
          setWait(false);
        });
      break;
      // VALIDAZIONE STEP 7
      case 7:
        if (!selectedTechnician) {
          valid = false;
          setWait(false);
          Swal.fire({
            icon: 'warning',
            title: 'Informazioni mancanti',
            text: "Non hai assegnato un tecnico per questa pratica"
          });
        }
        if (valid)
          handleSubmit(onSubmit)();
      break;
    }
    // Mi muovo verso lo step passato come parametro
    if (num == 7)
      setLastStep(true);
    else
      setLastStep(false);
    if (valid) {
      switch (num) {
        case 3:
          // Rimuovo tutti i beni (assets)
          remove();
          // Setto i beni presenti (assets)
          if (evaluation && evaluation.assets && evaluation.assets.length) {
            evaluation.assets.map((item, index) => {
              insert(index, item);
            });
          }
          /*
          if (evaluation && evaluation.assets && evaluation.assets.length) {
            evaluation.assets.map((item, index) => {
              setValue(`assets.${index}.id`, evaluation.assets[index].id);
            });
          }
          */
        break;
        case 6:
          // Carico i file dell'attività
          if (state && state.evaluation_id) {
            axios.post(window.SERVER_URL+'evaluations/get_files', { evaluation_id: state.evaluation_id }, {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }
            }).then(resf => {
              console.log(resf.data);
              setEvaluationFiles(resf.data.files);
            });
          }
        break;
      }

      setStep(num);
    }
  }
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    form_data['evaluation']['user_id'] = selectedCustomer.value;
    if (selectedTechnician)
      form_data['evaluation']['technician_id'] = selectedTechnician.value;
    if (selectedBillingCustomer)
      form_data['evaluation']['billing_user_id'] = selectedBillingCustomer.value;
    axios.post(window.SERVER_URL+'evaluations/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      if (res.data.success) {
        if (lastStep) { // se mi trovo all'ultimo step, mostro messaggio e cambio schermata
          setWait(false);
          Swal.fire({
            html: `<img src="${process.env.PUBLIC_URL}/gfx/Raggruppa138.png"><br><b>Ottimo lavoro!</b><br>La pratica con ID ${evaluation.id_commessa}<br>è stata inserita con successo.`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Carica nuova pratica",
            cancelButtonText: "Torna alla dashboard",
            confirmButtonColor: "#12275d",
            cancelButtonColor: "#12275d"
          }).then((result) => {
            if (result.isConfirmed) {
              // carica nuova pratica
              navigate('/evaluations', { state: { create_new: 1 } });
            } else {
              // torna alla dashboard
              navigate('/evaluations');
            }
          });
        } else {  // se mi trovo in uno degli step intermedi, faccio il get per avere i dati aggiornati della perizia
          axios.post(window.SERVER_URL+'evaluations/get', { id: res.data.evaluation_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            setEvaluation(res.data.evaluation);
            setValue("id", res.data.evaluation.id);

            // Setto i campi dei beni (assets)
            remove();
            if (res.data.evaluation && res.data.evaluation.assets && res.data.evaluation.assets.length) {
              res.data.evaluation.assets.map((item, index) => {
                insert(index, item);
              });
            }

            setWait(false);
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non è stato possibile salvare la pratica"
        });
      }
    }).catch(function(error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Non è stato possibile salvare la pratica"
      });
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1 style={{'textAlign':'left'}}>Modulo caricamento pratica</h1>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <div id="wizard_bar">
              <div className={step >= 1 ? 'step active' : 'step'}>Dati generali della pratica</div>
              <div className={step >= 2 ? 'step active' : 'step'}>Dati del cliente</div>
              <div className={step >= 3 ? 'step active' : 'step'}>Dati catastali beni da periziare</div>
              <div className={step >= 4 ? 'step active' : 'step'}>Dati contatto per sopralluogo</div>
              <div className={step >= 5 ? 'step active' : 'step'}>Dati di fatturazione</div>
              <div className={step >= 6 ? 'step active' : 'step'}>Caricamento documenti</div>
              <div className={step >= 7 ? 'step active' : 'step'}>Riepilogo pratica</div>
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col lg={9} className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" name="id" {...register("id")} value={ evaluation ? evaluation.id : null } />
              {/* STEP 1 - DATI GENERALI DELLA PRATICA */}
              <fieldset className={step == 1 ? 'wizard_step_content visible' : 'wizard_step_content'}>
                <Row><Col><label className='step_label'>DATI GENERALI DELLA PRATICA</label></Col></Row>
                <Row>
                  <Col>
                    <label>Cliente</label>
                    <Select value={selectedCustomer} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="evaluation[user_id]" options={selectCustomers} onChange={(e) => setSelectedCustomer(e)} />
                  </Col>
                  <Col>
                    <Button style={{'marginTop':'30px'}} onClick={() => { navigate('/customers/add') }}><DynamicIcon iconName='FaPlus' /> Aggiungi cliente</Button>
                  </Col>
                </Row>
                <Row style={{'marginTop':'20px'}}>
                  <Col>
                    <label>ID Commessa</label>
                    <input type="text" placeholder="ID Commessa" {...register("evaluation[id_commessa]")} />
                  </Col>
                  <Col>
                    <label>Tipo di pratica</label>
                    <select {...register("evaluation[evaluation_type_id]")}>
                      <option key="0" value="">- Seleziona la tipologia -</option>
                      { evaluationTypes && evaluationTypes.length ? evaluationTypes.map((type) => {
                      return <option key={ type.id } value={ type.id }>{ type.descrizione }</option>
                    }) : '' }
                    </select>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Dettaglio della commessa</label>
                    <textarea {...register("evaluation[dettaglio_commessa]")}></textarea>
                  </Col>
                </Row>
              </fieldset>
              {/* STEP 2 - DATI DEL CLIENTE */}
              <fieldset className={step == 2 ? 'wizard_step_content readonly visible' : 'wizard_step_content readonly'}>
                <Row><Col><label className='step_label'>DATI DEL CLIENTE</label></Col></Row>
                <Row>
                  <Col>
                    <label>Azienda</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.etichetta ? evaluation.user.etichetta : '-' }</p>
                  </Col>
                  <Col>
                    <label>Persona fisica</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.profile && evaluation.user.profile.referente_nome && evaluation.user.profile.referente_cognome ? evaluation.user.profile.referente_nome+' '+evaluation.user.profile.referente_cognome : '-' }</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>P. IVA</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.profile && evaluation.user.profile.partita_iva ? evaluation.user.profile.partita_iva : '-' }</p>
                  </Col>
                  <Col>
                    <label>Codice Fiscale</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.profile && evaluation.user.profile.codice_fiscale ? evaluation.user.profile.codice_fiscale : '-' }</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Indirizzo</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.profile && evaluation.user.profile.indirizzo ? evaluation.user.profile.indirizzo : '-' }</p>
                  </Col>
                  <Col>
                    <label>N. civico</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.profile && evaluation.user.profile.civico ? evaluation.user.profile.civico : '-' }</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label>Città</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.profile && evaluation.user.profile.comune ? evaluation.user.profile.comune : '-' }</p>
                  </Col>
                  <Col>
                    <label>Provincia</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.profile && evaluation.user.profile.province && evaluation.user.profile.province.nome ? evaluation.user.profile.province.nome : '-' }</p>
                  </Col>
                  <Col>
                    <label>CAP</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.profile && evaluation.user.profile.cap ? evaluation.user.profile.cap : '-' }</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Telefono</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.profile && evaluation.user.profile.telefono ? evaluation.user.profile.telefono : '-' }</p>
                  </Col>
                  <Col>
                    <label>Cellulare</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.profile && evaluation.user.profile.cellulare ? evaluation.user.profile.cellulare : '-' }</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={9}>
                    <label>E-mail</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.email ? evaluation.user.email : '-' }</p>
                  </Col>
                  <Col>
                    <label>Stato</label>
                    <p>{ evaluation && evaluation.user && evaluation.user.profile && evaluation.user.profile.stato ? evaluation.user.profile.stato : '-' }</p>
                  </Col>
                </Row>
              </fieldset>
              {/* STEP 3 - DATI CATASTALI BENI DA PERIZIARE */}
              <fieldset className={step == 3 ? 'wizard_step_content visible' : 'wizard_step_content'}>
                <Row>
                  <Col lg={9}>
                    <label className='step_label'>DATI CATASTALI BENI DA PERIZIARE</label>
                  </Col>
                  <Col>
                    <Button onClick={() => addAsset() }><DynamicIcon iconName='FaPlus' /> Aggiungi bene</Button>
                  </Col>
                </Row>
                {/* { assets && assets.length ? assets.map((ass, i) =>  */}
                {fields.map((item, index) =>
                  // <div className='asset_box' key={i}>
                  <div className='asset_box' key={item.id}>
                    <input type="hidden" {...register(`assets.${index}.id`)} />
                    <Row>
                      <Col lg={6}>
                        <label>Indirizzo *</label>
                        <input type="text" placeholder="Indirizzo" {...register(`assets.${index}.indirizzo`)} />
                      </Col>
                      <Col>
                        <label>N. civico *</label>
                        <input type="text" placeholder="Civico" {...register(`assets.${index}.civico`)} />
                      </Col>
                      <Col>
                        <label>Frazione / Località</label>
                        <input type="text" placeholder="Frazione o località" {...register(`assets.${index}.localita`)} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Città *</label>
                        <input type="text" placeholder="Città" {...register(`assets.${index}.comune`)} />
                      </Col>
                      <Col>
                        <label>Provincia *</label>
                        <select {...register(`assets.${index}.province_id`)}>
                          <option value="">- Seleziona la provincia -</option>
                          { provinces.map((prov) => 
                            <option value={ prov.id }>{ prov.nome }</option>
                          )}
                        </select>
                      </Col>
                      <Col>
                        <label>CAP *</label>
                        <input type="text" placeholder="CAP" {...register(`assets.${index}.cap`)} />
                      </Col>
                      <Col>
                        <label>Stato *</label>
                        <input type="text" placeholder="Stato" {...register(`assets.${index}.stato`)} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Tipo particella *</label>
                        <select {...register(`assets.${index}.parcel_type_id`)}>
                          <option value="">- Seleziona il tipo particella -</option>
                          { parcelTypes.map((type) => 
                            <option value={ type.id }>{ type.descrizione }</option>
                          )}
                        </select>
                      </Col>
                      <Col>
                        <label>Categoria catastale *</label>
                        <select {...register(`assets.${index}.registry_category_id`)}>
                          <option value="">- Seleziona la categoria catastale -</option>
                          { registryCategories.map((cat) => 
                            <option value={ cat.id }>{ cat.descrizione }</option>
                          )}
                        </select>
                      </Col>
                      <Col>
                        <label>&nbsp;</label>
                        {/* <input type="radio" /> Catasto
                        <input type="radio" /> Tavolare */}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Sezione</label>
                        <input type="text" placeholder="Sezione" {...register(`assets.${index}.sezione`)} />
                      </Col>
                      <Col>
                        <label>Foglio *</label>
                        <input type="text" placeholder="Foglio" {...register(`assets.${index}.foglio`)} />
                      </Col>
                      <Col>
                        <label>Mappale *</label>
                        <input type="text" placeholder="Mappale" {...register(`assets.${index}.mappale`)} />
                      </Col>
                      <Col>
                        <label>Subalterno *</label>
                        <input type="text" placeholder="Subalterno" {...register(`assets.${index}.subalterno`)} />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={9}>
                        <label>Note aggiuntive (se esistenti)</label>
                        <input type="text" placeholder="Note" {...register(`assets.${index}.note`)} />
                      </Col>
                      <Col>
                        {/* <Button style={{'marginTop':'30px'}} onClick={() => removeAsset(i) }><DynamicIcon iconName='TiDelete' /> Rimuovi bene</Button> */}
                        <Button style={{'marginTop':'30px'}} onClick={() => remove(index) }><DynamicIcon iconName='TiDelete' /> Rimuovi bene</Button>
                      </Col>
                    </Row>
                  </div>
              // ) : '' }
              )}
              </fieldset>
              {/* STEP 4 - DATI CONTATTO PER SOPRALLUOGO */}
              <fieldset className={step == 4 ? 'wizard_step_content visible' : 'wizard_step_content'}>
                <Row><Col><label className='step_label'>DATI CONTATTO PER SOPRALLUOGO</label></Col></Row>
                <Row>
                  <Col>
                    <label>Referente per sopralluogo *</label>
                    <input type="text" placeholder="Referente" {...register("evaluation[sopralluogo_referente]")} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Telefono 1 *</label>
                    <input type="text" placeholder="Telefono 1" {...register("evaluation[sopralluogo_telefono_1]")} />
                  </Col>
                  <Col>
                    <label>Telefono 2</label>
                    <input type="text" placeholder="Telefono 2" {...register("evaluation[sopralluogo_telefono_2]")} />
                  </Col>
                  <Col>
                    <label>Cellulare</label>
                    <input type="text" placeholder="cellulare" {...register("evaluation[sopralluogo_cellulare]")} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>E-mail</label>
                    <input type="text" placeholder="Indirizzo e-mail referente" {...register("evaluation[sopralluogo_email]")} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Note aggiuntive (se esistenti)</label>
                    <input type="text" placeholder="Note" {...register("evaluation[sopralluogo_note]")} />
                  </Col>
                </Row>
              </fieldset>
              {/* STEP 5 - DATI DI FATTURAZIONE */}
              <fieldset className={step == 5 ? 'wizard_step_content visible' : 'wizard_step_content'}>
                <Row><Col><label className='step_label'>DATI DI FATTURAZIONE</label></Col></Row>
                <Row>
                  <Col>
                    <label>Cliente</label>
                    <Select value={selectedBillingCustomer} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="billing[user_id]" options={selectCustomers} onChange={(e) => setSelectedBillingCustomer(e)} />
                  </Col>
                </Row>
                <br /><hr />
                <Row>
                  <Col>
                    <p><b>Se il cliente non è presente in anagrafica, inserisci i dati di fatturazione manualmente:</b></p>
                  </Col>
                </Row>
                <Row>
                  <input type="hidden" name="billing[id]" {...register("billing[id]")} value={ evaluation && evaluation.billing ? evaluation.billing.id : null } />
                  <Col>
                    <label>Nome</label>
                    <input type="text" placeholder="Nome" {...register("billing[nome]")} />
                  </Col>
                  <Col>
                    <label>Cognome</label>
                    <input type="text" placeholder="Cognome" {...register("billing[cognome]")} />
                  </Col>
                  <Col>
                    <label>Ragione sociale *</label>
                    <input type="text" placeholder="Ragione sociale" {...register("billing[ragione_sociale]")} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Partita IVA *</label>
                    <input type="text" placeholder="Partita IVA" {...register("billing[partita_iva]")} />
                  </Col>
                  <Col>
                    <label>Codice fiscale *</label>
                    <input type="text" placeholder="Codice fiscale" {...register("billing[codice_fiscale]")} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Comune *</label>
                    <input type="text" placeholder="Comune" {...register("billing[comune]")} />
                  </Col>
                  <Col>
                    <label>Provincia *</label>
                    <select {...register("billing[province_id]")}>
                      <option value="">- Seleziona la provincia -</option>
                      { provinces ? provinces.map((prov) => 
                        <option value={ prov.id }>{ prov.nome }</option>
                      ) : '' }
                    </select>
                  </Col>
                  <Col>
                    <label>Stato *</label>
                    <input type="text" placeholder="Stato" {...register("billing[stato]")} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>CAP *</label>
                    <input type="text" placeholder="CAP" {...register("billing[cap]")} />
                  </Col>
                  <Col>
                    <label>Indirizzo *</label>
                    <input type="text" placeholder="Indirizzo" {...register("billing[indirizzo]")} />
                  </Col>
                  <Col>
                    <label>Num. civico *</label>
                    <input type="text" placeholder="Civico" {...register("billing[civico]")} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Telefono</label>
                    <input type="text" placeholder="Telefono" {...register("billing[telefono]")} />
                  </Col>
                  <Col>
                    <label>Cellulare</label>
                    <input type="text" placeholder="Cellulare" {...register("billing[cellulare]")} />
                  </Col>
                </Row>
              </fieldset>
              {/* STEP 6 - CARICAMENTO DOCUMENTI */}
              <fieldset className={step == 6 ? 'wizard_step_content visible' : 'wizard_step_content'}>
                <Row><Col><label className='step_label'>CARICAMENTO DOCUMENTI</label></Col></Row>
                <Row style={{'marginBottom':'40px'}}>
                  <Col>
                    <p>Carica qui i file in tuo possesso relativi alla pratica. Ti ricordiamo che dal pannello GESTIONE DOCUMENTI puoi visualizzare, scaricare e caricare file per questa pratica in qualsiasi momento, anche una volta completato il caricamento.</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FileUploader handleChange={handleFileUpload} name="file" multiple={true} />
                  </Col>
                  {/* <Col>
                    <Button style={{'marginTop':'10px'}}>SELEZIONA FILE DA GESTIONE DOCUMENTI</Button>
                  </Col> */}
                </Row>
                <Row style={{'marginTop':'40px'}}>
                  <Col className='tab_wrapper'>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Nome file</th>
                          <th>Peso</th>
                          <th>Data caricamento</th>
                          <th style={{'width':'120px'}}></th>
                        </tr>
                      </thead>
                      <tbody>
                        { evaluationFiles && evaluationFiles.length ? evaluationFiles.map((f) => 
                          <tr>
                            <td>{ f.nome }</td>
                            <td>{ f.peso }</td>
                            <td>{ f.data_caricamento }</td>
                            <td className="actions">
                              <Button title="Scarica file" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></Button>
                              <Button title="Elimina file" onClick={() => { handleDeleteFile(f.id) }} className="delete"><DynamicIcon iconName='FaTrash' /></Button>
                            </td>
                          </tr>
                        ) : '' }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </fieldset>
              {/* STEP 7 - RIEPILOGO PRATICA */}
              <fieldset className={step == 7 ? 'wizard_step_content readonly visible' : 'wizard_step_content readonly'}>
                <Row><Col><label className='step_label'>RIEPILOGO PRATICA</label></Col></Row>
                { evaluation ? 
                  <>
                    <Row>
                      <Col>
                        <label>Pratica ID</label>
                        <p>{ evaluation.id }</p>
                      </Col>
                      <Col>
                        <label>Data caricamento</label>
                        <p>{ evaluation.data_caricamento ? evaluation.data_caricamento : (evaluation.created_at ? evaluation.created_at : '-') }</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Tipo pratica</label>
                        <p>{ evaluation.evaluation_type ? evaluation.evaluation_type.descrizione : '-' }</p>
                      </Col>
                      <Col>
                        <label>N. beni da periziare</label>
                        <p>{ evaluation.assets ? evaluation.assets.length : '-' }</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Cliente</label>
                        <p>{ evaluation.user ? evaluation.user.etichetta : '-' }</p>
                      </Col>
                      <Col>
                        <label>ID commessa</label>
                        <p>{ evaluation.id_commessa }</p>
                      </Col>
                    </Row>
                    <Row style={{'marginTop':'20px'}}><Col><label className='step_label'>ASSEGNA A TECNICO</label></Col></Row>
                    <Row>
                      <Col lg={9}>
                        <label>Tecnico</label>
                        <Select value={selectedTechnician} required={true} isDisabled={false} isLoading={false} isClearable={true} isRtl={false} isSearchable={true} name="evaluation[technician_id]" options={selectTechnicians} onChange={(e) => setSelectedTechnician(e)} />
                      </Col>
                      <Col>
                        <Button style={{'marginTop':'30px'}} onClick={() => { }}><DynamicIcon iconName='FaPlus' /> Aggiungi tecnico</Button>
                      </Col>
                    </Row>
                  </>
                : '' }
              </fieldset>
              {/* <Button type="submit" variant="primary" className="waste_btn">Salva</Button> */}
            </form>
          </Col>
          <Col>
            <h2>Fasi caricamento</h2>
            <div id="wizard_bar" className="vert">
              <div className={step >= 1 ? 'step active' : 'step'}>Dati generali della pratica</div>
              <div className={step >= 2 ? 'step active' : 'step'}>Dati del cliente</div>
              <div className={step >= 3 ? 'step active' : 'step'}>Dati catastali beni da periziare</div>
              <div className={step >= 4 ? 'step active' : 'step'}>Dati contatto per sopralluogo</div>
              <div className={step >= 5 ? 'step active' : 'step'}>Dati di fatturazione</div>
              <div className={step >= 6 ? 'step active' : 'step'}>Caricamento documenti</div>
              <div className={step >= 7 ? 'step active' : 'step'}>Riepilogo pratica</div>
            </div>
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            { step > 1 ?
              <Button onClick={() => goToStep(step-1)}>
                <DynamicIcon iconName='IoIosArrowBack' /> Indietro
              </Button>
            : '' }
            { step < 7 ?
              <Button className='inverse' onClick={() => goToStep(step+1)}>
                <DynamicIcon iconName='IoIosArrowForward' /> Continua
              </Button>
            : '' }
            { step == 7 ?
              <Button className='inverse' onClick={() => goToStep(7)}>
                <DynamicIcon iconName='IoIosArrowForward' /> Concludi caricamento pratica
              </Button>
            : '' }
          </Col>
        </Row>
        <br /><hr />
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => { navigate('/evaluations', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } }); }}>
              <DynamicIcon iconName='IoIosArrowBack' /> Torna alle pratiche
            </Button> 
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default EvaluationsAdd;