import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation, redirect } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// GOOGLE MAPS
import { Wrapper, Status } from '@googlemaps/react-wrapper';
// AXIOS
import axios from 'axios';
// PARTIALS
import Map from '../partials/map';
// REACT HOOK FORM
import { useForm, useFieldArray } from "react-hook-form";
// REACT SELECT
import Select from 'react-select';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT FILE UPLOADER
import { FileUploader } from "react-drag-drop-files";
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
import { FaLaptopHouse } from 'react-icons/fa';

function EvaluationsCon() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // CUSTOM HOOKS
  const { handleSuspension, geocode_point } = useHelpers();
  // FORM
  const { register, unregister, control, handleSubmit, trigger, getValues, setValue, reset, formState: { errors } } = useForm({
    shouldUnregister: true
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "assets"
  });
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [evaluation, setEvaluation] = useState(null);
  const [fotoCategories, setFotoCategories] = useState(null);
  const [step, setStep] = useState(1);
  const [lastStep, setLastStep] = useState(false);
  const [diffTypes, setDiffTypes] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [diffResBtnArray, setDiffResBtnArray] = useState({});
  // DATA - POPUP
  const [popupOptions, setPopupOptions] = useState([]);
  // DATA - MAPPA
  const [initCoords, setInitCoords] = useState({ lat: 42.850127, lng: 13.6246959 });  
  const [coords, setCoords] = useState({ lat: 42.850127, lng: 13.6246959 });  
  const [center, setCenter] = useState({ lat: 42.850127, lng: 13.6246959 });
  const [zoom, setZoom] = useState(18);
  const [mapKey, setMapKey] = useState(0);
  // FILE UPLOADER
  const [file, setFile] = useState(null);
  const [inspectionFotoFiles, setInspectionFotoFiles] = useState([]);
  const [inspectionDiffFiles, setInspectionDiffFiles] = useState([]);
  const [inspectionFotoCats, setInspectionFotoCats] = useState([]);
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    // Se sto modificando una pratica esistente, carico le informazioni relative
    if (state && state.evaluation_id) {
      axios.post(window.SERVER_URL+'evaluations/get', { id: state.evaluation_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        let tmp_eval = res.data.evaluation;
        setEvaluation(res.data.evaluation);

        // Carico le province
        axios.post(window.SERVER_URL+'provinces/list', { }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(resp => {
          setProvinces(resp.data.provinces);

          // Carico le categorie di foto
          axios.post(window.SERVER_URL+'inspection_image_categories/list', {}, {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer '+authobj.token
            }
          }).then(resc => {
            console.log(resc.data);
            setFotoCategories(resc.data.image_categories);

            // Carico le foto del sopralluogo
            axios.post(window.SERVER_URL+'inspections/get_files', { evaluation_id: state.evaluation_id, file_type: 'foto' }, {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }
            }).then(resf => {
              console.log(resf.data);
              setInspectionFotoFiles(resf.data.files);
              setInspectionFotoCats(resf.data.cats);

              // Carico le difformità del sopralluogo
              axios.post(window.SERVER_URL+'inspections/get_files', { evaluation_id: state.evaluation_id, file_type: 'difformita' }, {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }
              }).then(resf => {
                console.log(resf.data);
                setInspectionDiffFiles(resf.data.files);

                // Carico le opzioni per la select del popup delle foto
                axios.post(window.SERVER_URL+'popup_motifs/list', { sezione: 'inspection_step_3' }, {
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                  }
                }).then(reso => {
                  console.log(reso.data);
                  setPopupOptions(reso.data.motifs);

                  // Carico le tipologie di difformità
                  axios.post(window.SERVER_URL+'inspection_differences/list', {}, {
                    headers: {
                      'Access-Control-Allow-Origin': '*',
                      'Authorization': 'Bearer '+authobj.token
                    }
                  }).then(resd => {
                    console.log(resd.data);
                    setDiffTypes(resd.data.differences);

                    // Setto i campi della form
                    let xfields = {};
                    if (res.data.evaluation && res.data.evaluation.indirizzo) {
                      for (const [key, val] of Object.entries(res.data.evaluation.indirizzo))
                        if (typeof val !== 'object')
                          xfields['indirizzo['+key+']'] = val;
                    }
                    if (res.data.evaluation && res.data.evaluation.inspection) {
                      for (const [key, val] of Object.entries(res.data.evaluation.inspection))
                        if (typeof val !== 'object')
                          xfields['inspection['+key+']'] = val;
                      // Le risposte del questionario le gestisco a parte
                      if (res.data.evaluation.inspection && res.data.evaluation.inspection.questionario) {
                        JSON.parse(res.data.evaluation.inspection.questionario).forEach((risp, index) => {
                          xfields['inspection[questionario][risposta_'+(index+1)+']'] = risp;
                        });
                      }
                    }
                    if (res.data.evaluation && res.data.evaluation.assets && res.data.evaluation.assets.length) {
                      res.data.evaluation.assets.forEach((ass) => {
                        if (ass.difformita !== null) {
                          xfields['differences[difformita][_'+ass.id+']'] = ass.difformita.toString();
                          setDiffResBtnArray(prevState => ({ ...prevState, ['_'+ass.id]: ass.difformita.toString() }));
                        }
                      });
                    }

                    let tmp_coords = geocode_point(tmp_eval.indirizzo.indirizzo+' '+tmp_eval.indirizzo.civico+' '+tmp_eval.indirizzo.cap+' '+tmp_eval.indirizzo.comune);
                    tmp_coords.then(response => {
                      console.log(tmp_coords);
                      let tmp_lat = response.data.results && response.data.results.length ? response.data.results[0].geometry.location.lat : '';
                      let tmp_lng = response.data.results && response.data.results.length ? response.data.results[0].geometry.location.lng : '';
                      setInitCoords({ lat: tmp_lat, lng: tmp_lng });
                      // VEDO SE CI SONO COORDINATE AGGIORNATE SALVATE PER IL SOPRALLUOGO ALTRIMENTI USO QUELLE CALCOLATE
                      tmp_lat = parseFloat(res.data.evaluation && res.data.evaluation.inspection && res.data.evaluation.inspection.lat ? res.data.evaluation.inspection.lat : tmp_lat);
                      tmp_lng = parseFloat(res.data.evaluation && res.data.evaluation.inspection && res.data.evaluation.inspection.lng ? res.data.evaluation.inspection.lng : tmp_lng);
                      setCoords({ lat: tmp_lat, lng: tmp_lng });
                      setCenter({ lat: tmp_lat, lng: tmp_lng });
                      // SETTO I VALORI DEI CAMPI DELLA FORM
                      xfields['inspection[lat]'] = tmp_lat;
                      xfields['inspection[lng]'] = tmp_lng;

                      reset(xfields);
                    });
                  });
                });
              });
            });
          });
        });

        setWait(false);
      });
    } else {
      setWait(false);
    }
  }, [reset]);

  const handleFileUpload = (files) => {
    console.log(files);
    if (evaluation.id) {
      setWait(true);

      // Carico i files
      const formData = new FormData();
      var readed_files = [];
      // Ciclo sui vari file
      const promises = [];
      Array.from(files).forEach(function(f, idx) {
        promises.push(new Promise((resolve, reject) => {
          var fileReader = new FileReader();
          fileReader.onload = function(evt) {
            let readed = {
              'metadata': {
                'name': f.name,
                'type': f.type,
                'size': f.size
              },
              'content': evt.target.result
            };
            readed_files.push(readed);
            resolve(readed);
          };
          fileReader.onerror = reject;
          fileReader.readAsDataURL(f);
        }));
      });

      Promise.all(promises).then((results) => {
        formData.append('uploads', JSON.stringify(results));
        formData.append('evaluation_id', evaluation.id);
        formData.append('file_type', step == 2 ? 'foto' : 'difformita');
        axios.post(window.SERVER_URL+'inspections/upload', formData, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token,
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          if (res.data.success == true) {
            setWait(false);
            Swal.fire({
              icon: 'success',
              title: 'Caricamento completato',
              html: '<b>'+res.data.log.length+' file</b> sono stati caricati correttamente'
            }).then((result) => {
              if (result.isConfirmed) {
                // Recupero la lista aggiornata dei files
                setWait(true);
                axios.post(window.SERVER_URL+'inspections/get_files', { evaluation_id: evaluation.id, file_type: (step == 2 ? 'foto' : 'difformita') }, {
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': 'Bearer '+authobj.token
                  }
                }).then(reslist => {
                  console.log(reslist.data);
                  if (step == 2) {
                    setInspectionFotoFiles(reslist.data.files);
                    setInspectionFotoCats(reslist.data.cats);
                  } else {
                    setInspectionDiffFiles(reslist.data.files);
                  }
                  setWait(false);
                });
              }
            });
          } else {
            let log = "";
            res.data.log.forEach(function(row, idx) {
              log += row.name+': '+(row.result ? 'caricato' : 'fallito')+'<br>'
            });
            Swal.fire({
              icon: res.data.log.length ? 'warning' : 'error',
              title: res.data.log.length ? 'Uno o più caricamenti sono falliti' : 'Caricamento dei file fallito',
              html: log
            });
          }
        });
      });

      setWait(false);
    }
  };

  const handleDeleteFile = (id_file) => {
    Swal.fire({
      html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 4.png"><br>
              <b>ATTENZIONE!</b><br>
              <p><b>Sei sicuro di voler scartare questo file?<br>L'operazione non è reversibile</b></p>`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Scarta',
      denyButtonText: 'Annulla',
      confirmButtonColor: "#12275d",
      cancelButtonColor: "#12275d",
      width: '800px',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'evaluations/delete_file', { id: id_file }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success === true) {
            // setInspectionFotoFiles(inspectionFotoFiles.filter(f => f.id !== id_file));
            unregister(`differences[files][_${id_file}]`);
            setWait(true);
            axios.post(window.SERVER_URL+'inspections/get_files', { evaluation_id: evaluation.id, file_type: (step == 2 ? 'foto' : 'difformita') }, {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': 'Bearer '+authobj.token
              }
            }).then(reslist => {
              console.log(reslist.data);
              if (step == 2) {
                setInspectionFotoFiles(reslist.data.files);
                setInspectionFotoCats(reslist.data.cats);
              } else {
                setInspectionDiffFiles(reslist.data.files);
              }
              setWait(false);

              Swal.fire({
                icon: 'success',
                title: 'File cancellato',
                // text: 'Il movimento è stato cancellato',
              });
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Errore',
              text: 'Si è verificato un problema nella cancellazione'
            });
          }
        });
      }
    });
  }

  const assignFotoCategory = (foto_id, cat_id) => {
    console.log("FOTO ID: "+foto_id);
    console.log("CAT ID: "+cat_id);

    axios.post(window.SERVER_URL+'inspections/set_image_category', { id: foto_id, image_category_id: cat_id }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setWait(false);
      if (res.data.success !== true) {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: 'Non è stato possibile cambiare la categoria'
        });
      } else {
        setWait(true);
        axios.post(window.SERVER_URL+'inspections/get_files', { evaluation_id: evaluation.id, file_type: (step == 2 ? 'foto' : 'difformita') }, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }
        }).then(reslist => {
          console.log(reslist.data);
          setInspectionFotoFiles(reslist.data.files);
          setInspectionFotoCats(reslist.data.cats);
          setWait(false);
        });
      }
    });
  }
  
  // const valid = await trigger(["evaluation[id_commessa]", "evaluation[evaluation_type_id]"]);

  const goToStep = async (num) => {
    setWait(true);
    console.log("STO ALLO STEP "+step);
    console.log("VADO ALLO STEP "+num);
    // Validazione dei dati a seconda dello step
    let valid = true;
    const values = getValues();
    switch (step) {
      // VALIDAZIONE STEP 1
      case 1:
        if (!values.inspection.lat || !values.inspection.lng) {
          valid = false;
          setWait(false);
          Swal.fire({
            icon: 'warning',
            title: 'Informazioni mancanti',
            text: "Non hai impostato le coordinate reali"
          });
        }
        if (valid)
          handleSubmit(onSubmit)();
      break;
      // VALIDAZIONE STEP 2
      case 2:
        console.log("VALIDAZIONE STEP 3");
        setWait(false);
        if (num == 3) {
          let foto_ok = true;

          inspectionFotoCats.forEach((cat) => {
            if (cat.min && cat.stato != 'success')
              foto_ok = false;
          });

          if (!foto_ok) {
            let popup_select = '<select id="nofoto_motivo" name="nofoto_motivo" style="display: none;">';
            popup_select += '<option value="">- Seleziona la motivazione -</option>';
            popupOptions.forEach((opt) => {
              popup_select += '<option value="'+opt.id+'">'+opt.descrizione+'</option>';
            });
            popup_select += '<option value="4" selected>---</option>'; // ABBIAMO FORZATO QUESTA MOTIVAZIONE SENZA CHE L'UTENTE LA VEDA
            popup_select += '</select>';

            Swal.fire({
              html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 4.png"><br>
                      <b>ATTENZIONE!</b><br>
                      <p>Le foto presenti nella perizia non raggiungono<br>la quantità minima richiesta<br>Indica il motivo.</p>
                      ${popup_select}                 
                      <textarea id="nofoto_note" name="no_foto_note" placeholder="Note opzionali"></textarea>
                      <br><br>`,
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText: "Procedi",
              cancelButtonText: "Torna indietro",
              confirmButtonColor: "#12275d",
              cancelButtonColor: "#12275d",
              width: '800px',
              preConfirm: () => {
                return [
                  document.getElementById("nofoto_motivo").value,
                  document.getElementById("nofoto_note").value
                ];
              }
            }).then((result) => {
              if (result.isConfirmed) {
                setWait(true);
                axios.post(window.SERVER_URL+'notebooks/save', { evaluation_id: state.evaluation_id, popup_motif_id: result.value[0], note: result.value[1] }, { headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': 'Bearer '+authobj.token
                }}).then(res => {
                  valid = true;
                  setWait(false);
                });
              } else {
                setStep(2);
              }
            });
          }
        }
      break;
      // VALIDAZIONE STEP 3
      case 3:
        setWait(false);
        handleSubmit(onSubmit)();

        let diff_set = true;
        if (evaluation && evaluation.assets && evaluation.assets.length) {
          evaluation.assets.forEach((ass) => {
            if (!values.differences.difformita['_'+ass.id])
              diff_set = false;
          });
        }

        if (!diff_set) {
          Swal.fire({
            html: `<img src="${process.env.PUBLIC_URL}/gfx/Griglia di ripetizione 4.png"><br>
                    <b>ATTENZIONE!</b><br>
                    <p>Non è stata dichiarata<br>la difformità su tutti i beni.</p>`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Procedi",
            cancelButtonText: "Torna indietro",
            confirmButtonColor: "#12275d",
            cancelButtonColor: "#12275d",
            width: '800px'
          }).then((result) => {
            if (!result.isConfirmed) {
              setStep(3);
            }
          });
        }
      break;
      // VALIDAZIONE STEP 4
      case 4:
        setWait(false);
      break;
    }
    // Mi muovo verso lo step passato come parametro
    if (num == 999) {
      setLastStep(true);
      setWait(false);

      console.log(">>> STEP "+step);
      console.log(">>> NUM "+num);

      handleSubmit(onSubmit)();
      showEndingPopup();

    } else {
      setLastStep(false);
    }
    if (valid && num != 999) {
      switch (num) {
      }
      setStep(num);
    }
  }

  const showEndingPopup = () => {
    Swal.fire({
      html: `<img src="${process.env.PUBLIC_URL}/gfx/Raggruppa138.png"><br><b>Conferma validazione pratica</b><br>Vuoi confermare la validazione<br>della pratica ID ${evaluation.id_commessa}?`,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Sì, lo confermo",
      cancelButtonText: "No, non confermo",
      confirmButtonColor: "#12275d",
      cancelButtonColor: "#12275d"
    }).then((result) => {
      if (result.isConfirmed) {
        setValue('completato', 'qual-cont');
        handleSubmit(onSubmit)();
        Swal.fire({
          html: `<img src="${process.env.PUBLIC_URL}/gfx/Raggruppa138.png"><br><b>Ottimo lavoro!</b><br>La tua azione è andata a buon fine`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Torna alla dashboard",
          // cancelButtonText: "Torna alla dashboard",
          confirmButtonColor: "#12275d",
          cancelButtonColor: "#12275d"
        }).then((result) => {
          if (result.isConfirmed) {
            // torna alla dashboard
            navigate('/dashboard');
          }
        });
      }
    });
  }

  const handleResolutionBtnState = (ass_id, val) => {
    setDiffResBtnArray(prevState => ({ ...prevState, ['_'+ass_id]: val.toString() }));
  }
  
  const handleResolution = (ass_id) => {
    axios.post(window.SERVER_URL+'inspection_differences/get_resolution', { id: ass_id }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      Swal.fire({
        html: `<img src="${process.env.PUBLIC_URL}/gfx/feedback_positivo.png"><br>
                <b>Specifica risoluzione difformità</b><br><br>
                <label>Importo stimato (€)</label>
                <input type="text" id="diff_ris_amount" name="diff_ris_amount" value="${res.data.difformita_importo ? res.data.difformita_importo : ''}" placeholder="Importo stimato">
                <label>Descrizione risoluzione</label>
                <textarea id="diff_ris_note" name="diff_ris_note" placeholder="Note opzionali">${res.data.difformita_note ? res.data.difformita_note : ''}</textarea>
                <br><br>`,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Salva",
        cancelButtonText: "Annulla",
        confirmButtonColor: "#12275d",
        cancelButtonColor: "#12275d",
        width: '800px',
        preConfirm: () => {
          return [
            document.getElementById("diff_ris_amount").value,
            document.getElementById("diff_ris_note").value
          ];
        }
      }).then((result) => {
        if (result.isConfirmed) {
          setWait(true);
          axios.post(window.SERVER_URL+'inspection_differences/save_resolution', { id: ass_id, difformita_importo: result.value[0], difformita_note: result.value[1] }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            setWait(false);
            Swal.fire({
              icon: 'success',
              title: 'Salvataggio effettuato',
              text: "I dati della risoluzione sono stati correttamente salvati"
            });
          });
        }
      });
    });
  }
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    form_data.evaluation_id = state.evaluation_id;
    axios.post(window.SERVER_URL+'inspections/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      if (res.data.success) {
        if (!lastStep) { // se mi trovo in uno degli step intermedi, faccio il get per avere i dati aggiornati della perizia
          axios.post(window.SERVER_URL+'evaluations/get', { id: state.evaluation_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            setEvaluation(res.data.evaluation);
            setWait(false);
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non è stato possibile salvare la rendicontazione"
        });
      }
    }).catch(function(error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Non è stato possibile salvare la rendicontazione"
      });
    });
  }

  const render = (status) => {
    if (status === Status.SUCCESS) {
      return <Map key={mapKey} center={center} zoom={zoom} width={'100%'} height={'600px'} checks={[coords]} newcoords={newcoords} noControls />;
    }
  }

  const newcoords = (new_coords) => {
    // SETTO I VALORI DEI CAMPI DELLA FORM
    setValue('inspection[lat]', new_coords.lat);
    setValue('inspection[lng]', new_coords.lng);
    setCoords(new_coords);
    setCenter(new_coords);
  }

  const recalcCoords = () => {
    const values = getValues();
    console.log(values);
    let tmp_coords = geocode_point(values.indirizzo.indirizzo+' '+values.indirizzo.civico+' '+values.indirizzo.cap+' '+values.indirizzo.comune);
    tmp_coords.then(response => {
      console.log(tmp_coords);
      let tmp_lat = response.data.results[0].geometry.location.lat;
      let tmp_lng = response.data.results[0].geometry.location.lng;
      setInitCoords({ lat: tmp_lat, lng: tmp_lng });
      setCenter({ lat: tmp_lat, lng: tmp_lng });
      setCoords({ lat: tmp_lat, lng: tmp_lng });
      setValue('inspection[lat]', tmp_lat);
      setValue('inspection[lng]', tmp_lng);
    });
  }

  const viewGoogleMaps = () => {
    const values = getValues();
    window.open('https://www.google.com/maps/@'+values.inspection.lat+','+values.inspection.lng+',12?q='+values.inspection.lat+','+values.inspection.lng);
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1 style={{'textAlign':'left'}}>
              Controllo pratica: 
              <span className='bordered_info full'>{ evaluation ? evaluation.id_commessa : '' }</span>
              <span className='bordered_info grey'>{ evaluation && evaluation.evaluation_type ? evaluation.evaluation_type.descrizione : '' }</span>
            </h1>
          </Col>
        </Row>
        <Row>
          {/* <Col className="custom_form_wrapper"> */}
          <Col lg={9} className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" name="completato" {...register("completato")} />
              <input type="hidden" name="id" {...register("id")} value={ evaluation ? evaluation.id : null } />
              {/* STEP 1 - COORDINATE E DATI CATASTALI */}
              <fieldset className={step == 1 ? 'wizard_step_content reg visible' : 'wizard_step_content reg'}>
                <Row>
                  <Col>
                    <h2>Controllo coordinate e dati catastali</h2>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col>
                      <label className='step_label'>Indirizzo</label>
                      <Row>
                        <Col>
                          <label>Indirizzo</label>
                          <input type="text" placeholder="Indirizzo" {...register("indirizzo[indirizzo]")} />
                        </Col>
                        <Col lg={2}>
                          <label>Civico</label>
                          <input type="text" placeholder="Civico" {...register("indirizzo[civico]")} />
                        </Col>
                        <Col>
                          <label>Frazione / Località</label>
                          <input type="text" placeholder="Frazione o località" {...register("indirizzo[localita]")} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Città</label>
                          <input type="text" placeholder="Comune" {...register("indirizzo[comune]")} />
                        </Col>
                        <Col>
                          <label>Provincia</label>
                          <select {...register("indirizzo[province_id]")}>
                            <option value="">- Seleziona la provincia -</option>
                            { provinces.map((prov) => 
                              <option value={ prov.id }>{ prov.nome }</option>
                            )}
                          </select>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>CAP</label>
                          <input type="text" placeholder="CAP" {...register("indirizzo[cap]")} />
                        </Col>
                        <Col>
                          <label>Stato</label>
                          <input type="text" placeholder="Stato" {...register("indirizzo[stato]")} />
                        </Col>
                      </Row>
                      <label className='step_label' style={{'marginTop':'20px'}}>Coordinate calcolate</label>
                      <Row>
                        <Col>
                          <label>Latitudine</label>
                          <p>{ initCoords && initCoords.lat ? initCoords.lat : '-' }</p>
                        </Col>
                        <Col>
                          <label>Longitudine</label>
                          <p>{ initCoords && initCoords.lng ? initCoords.lng : '-' }</p>
                        </Col>
                        <Col lg={5}>
                          <Button onClick={() => recalcCoords()} style={{'marginTop':'10px'}}>Ricalcola coordinate</Button>
                        </Col>
                      </Row>
                      <label className='step_label' style={{'marginTop':'20px'}}>Coordinate reali</label>
                      <Row>
                        <Col>
                          <label>Latitudine</label>
                          <input type="text" placeholder="Latitudine" {...register("inspection[lat]")} />
                        </Col>
                        <Col>
                          <label>Longitudine</label>
                          <input type="text" placeholder="Latitudine" {...register("inspection[lng]")} />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Wrapper apiKey={window.GMAPS_APIKEY} render={render}></Wrapper>
                      <Button onClick={() => viewGoogleMaps()} style={{'marginTop':'20px','float':'right'}}>Vedi su Google Maps</Button>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col>
                    <label className='step_label'>Foto contesto urbanistico</label>
                  </Col>
                </Row>
                <Row style={{'marginBottom':'0','marginTop':'20px'}}>
                  <Col>
                    { inspectionFotoFiles && inspectionFotoFiles.length ? inspectionFotoFiles.map((f) =>
                      f.inspection_image_category && (f.inspection_image_category.descrizione.toLowerCase().match("urbanistico") || f.inspection_image_category.descrizione.toLowerCase().match("civico")) ?
                        <div className='fotoThumbWrapper'>
                          <p>{ f.inspection_image_category ? f.inspection_image_category.descrizione : '' }</p>
                          <div className='fotoThumb' style={{'backgroundImage':"url('"+f.link+"')"}}></div>
                          <div className='bottom_bar'>
                            <img src={process.env.PUBLIC_URL+'/gfx/SPF_AssetsIcone-32.png'} style={{'height':'16px'}} />
                            <span className='filename'>{ f.nome }</span>
                          </div>
                        </div>
                      : ''
                    ) : '' }
                  </Col>
                </Row>
                <Row style={{'marginTop':'20px'}}>
                  <Col>
                    <label className='step_label'>Altre foto</label>
                  </Col>
                </Row>
                <Row>
                  <Col className='tab_wrapper'>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Tipologia</th>
                          <th style={{'width':'120px'}}>Azioni</th>
                        </tr>
                      </thead>
                      <tbody>
                        { inspectionFotoFiles && inspectionFotoFiles.length ? inspectionFotoFiles.map((f) => 
                          f.inspection_image_category && !f.inspection_image_category.descrizione.toLowerCase().match("urbanistico") && !f.inspection_image_category.descrizione.toLowerCase().match("civico") ?
                            <tr>
                              <td>{ f.nome }</td>
                              <td>{ f.inspection_image_category ? f.inspection_image_category.descrizione : '-' }</td>
                              <td className="actions">
                                <Button title="Scarica file" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></Button>
                              </td>
                            </tr>
                          : ''
                        ) : '' }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className='step_label'>Elenco file forniti</label>
                  </Col>
                </Row>
                <Row>
                  <Col className='tab_wrapper'>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th style={{'width':'120px'}}>Azioni</th>
                        </tr>
                      </thead>
                      <tbody>
                        { evaluation && evaluation.files && evaluation.files.length ? evaluation.files.map((f) => 
                          <tr>
                            <td>{ f.nome }</td>
                            <td className="actions">
                              <Button title="Scarica file" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></Button>
                            </td>
                          </tr>
                        ) : '' }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className='step_label'>Elenco dati catastali beni della pratica</label>
                  </Col>
                </Row>
                <Row>
                  <Col className='tab_wrapper'>
                    <Table responsive>
                    <thead>
                      <tr>
                        <th>ID bene</th>
                        <th>Tipo</th>
                        <th>Foglio</th>
                        <th>Particella</th>
                        <th>Sub</th>
                        <th>Cat</th>
                        {/* <th>Indirizzo</th> */}
                        {/* <th>Note</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      { evaluation && evaluation.assets && evaluation.assets.length ? evaluation.assets.map((asset) => {
                          return <tr key={ asset.id }>
                            <td>{ asset.id }</td>
                            <td>{ asset.parcel_type ? asset.parcel_type.sigla : '-' }</td>
                            <td>{ asset.foglio }</td>
                            <td>{ asset.mappale }</td>
                            <td>{ asset.subalterno }</td>
                            <td>{ asset.registry_category ? asset.registry_category.descrizione : '-' }</td>
                            {/* <td>{ asset.indirizzo+' '+asset.civico }</td> */}
                            {/* <td>{ asset.note }</td> */}
                          </tr>
                      }) : '' }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </fieldset>
              {/* STEP 2 - RILEVAMENTI FOTOGRAFICI */}
              <fieldset className={step == 2 ? 'wizard_step_content reg visible' : 'wizard_step_content reg'}>
                <Row>
                  <Col>
                    <h2>Controllo rilevamenti fotografici</h2>
                  </Col>
                </Row>
                <div className='inner_wrapper'>
                  <Row>
                    <Col lg={7}>
                      <ul id="foto_cats_list">
                        { inspectionFotoCats && inspectionFotoCats.length ? inspectionFotoCats.map((c) => 
                          <li key={c.id}>
                            <div className={'foto_cat_state '+c.stato}></div>
                            <b>{ c.nome }</b> { c.min ? '(min '+c.min+' foto)' : '' }
                          </li>
                        ) : '' }
                      </ul>
                    </Col>
                    <Col lg={5}>
                      <Row>
                        <Col>
                          <label>Amministratore</label>
                          <input type="text" placeholder="Nome" {...register("inspection[amministratore_nome]")} />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label>Telefono</label>
                          <input type="text" placeholder="Recapito telefonico" {...register("inspection[amministratore_telefono]")} />
                        </Col>
                        <Col>
                          <label>E-mail</label>
                          <input type="email" placeholder="Indirizzo email" {...register("inspection[amministratore_email]")} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col>
                    <label className='step_label'>Elenco file</label>
                  </Col>
                </Row>
                <Row>
                  <Col className='tab_wrapper'>
                    { inspectionFotoFiles && inspectionFotoFiles.length ? inspectionFotoFiles.map((f) => 
                      <div className={ f.user && f.user.role && f.user.role.slug == 'qual-cont' ? 'fotoThumbWrapper added' : 'fotoThumbWrapper' }>
                        <p>{ f.inspection_image_category ? f.inspection_image_category.descrizione : '' }</p>
                        <div className='fotoThumb' style={{'backgroundImage':"url('"+f.link+"')"}}></div>
                        <div className='bottom_bar'>
                          <img src={process.env.PUBLIC_URL+'/gfx/SPF_AssetsIcone-32.png'} style={{'height':'16px'}} />
                          <span className='filename'>{ f.nome }</span>
                          <a href="#" onClick={(e) => { e.preventDefault(); return false; }} className='command_menu_link'>
                            <img src={process.env.PUBLIC_URL+'/gfx/SPF_AssetsIcone-31.png'} style={{'height':'14px'}} />
                            <div className='command_panel'>
                              <a href="#" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /> Scarica</a>
                              <a href="#" onClick={() => { handleDeleteFile(f.id) }} className="delete"><DynamicIcon iconName='FaTrash' /> Scarta</a>
                              <hr />
                              <label><DynamicIcon iconName='BiSolidPencil' /> Modifica tipologia</label>
                              <select onChange={(e) => { assignFotoCategory(f.id, e.target.value) }} className='noMargin'>
                                <option value="">- Scegli categoria -</option>
                                { fotoCategories && fotoCategories.length ? fotoCategories.map((cat) => 
                                  <option key={cat.id} value={cat.id} selected={cat.id === f.inspection_image_category_id}>{cat.descrizione}</option>
                                ) : '' }
                              </select>
                            </div>
                          </a>
                        </div>
                      </div>
                    ) : '' }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className='step_label'>Carica qui le tue aggiunte</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='uploader_wrapper'>
                      <FileUploader handleChange={handleFileUpload} name="file" multiple={true} label="TRASCINA E RILASCIA I FILE QUI PER CARICARLI" classes="drop_zone" />
                    </div>
                  </Col>
                </Row>
              </fieldset>
              {/* STEP 3 - SCHEDA CATASTALE E DIFFORMITÀ */}
              <fieldset className={step == 3 ? 'wizard_step_content reg visible' : 'wizard_step_content reg'}>
                <Row>
                  <Col>
                    <h2>Controllo scheda catastale e difformità</h2>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className='step_label'>Elenco file</label>
                  </Col>
                </Row>
                <Row>
                  <Col className='tab_wrapper'>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          {/* <th>Peso</th>
                          <th>Data caricamento</th> */}
                          <th>Tripletta catastale</th>
                          <th style={{'width':'120px'}}>Azioni</th>
                        </tr>
                      </thead>
                      <tbody>
                        { inspectionDiffFiles && inspectionDiffFiles.length ? inspectionDiffFiles.map((f) => 
                          <tr className={ f.user && f.user.role && f.user.role.slug == 'qual-cont' ? 'added' : '' }>
                            <td>{ f.nome }</td>
                            {/* <td>{ f.peso }</td>
                            <td>{ f.data_caricamento }</td> */}
                            <td>
                              <select className='noMargin' {...register(`differences[files][_${f.id}]`)}>
                                <option value="">- Scegli tripletta -</option>
                                { evaluation && evaluation.assets && evaluation.assets.length ? evaluation.assets.map((ass) => 
                                  <option key={ass.id} value={ass.id} selected={f.asset_id == ass.id}>{ass.foglio} {ass.mappale} {ass.subalterno}</option>
                                ) : '' }
                              </select>
                            </td>
                            <td className="actions">
                              <Button title="Scarica file" onClick={() => { window.open(f.link); }}><DynamicIcon iconName="FaDownload" /></Button>
                              <Button title="Elimina file" onClick={() => { handleDeleteFile(f.id) }} className="delete"><DynamicIcon iconName='FaTrash' /></Button>
                            </td>
                          </tr>
                        ) : '' }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className='step_label'>Carica qui le tue aggiunte</label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='uploader_wrapper'>
                      <FileUploader handleChange={handleFileUpload} name="file" multiple={true} label="TRASCINA E RILASCIA I FILE QUI PER CARICARLI" classes="drop_zone" />
                    </div>
                  </Col>
                </Row>
                <Row style={{'marginTop':'20px'}}>
                  <Col>
                    <label className='step_label'>Riscontro difformità</label>
                  </Col>
                </Row>
                <Row>
                  <Col className='tab_wrapper'>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th style={{'width':'50px'}}>Tipo</th>
                          <th style={{'width':'80px'}}>Foglio</th>
                          <th style={{'width':'80px'}}>Particella</th>
                          <th style={{'width':'80px'}}>Sub</th>
                          <th style={{'paddingLeft':'100px'}}>Sono state riscontrate difformità?</th>
                          <th>Tipo difformità</th>
                          <th style={{'width':'120px'}}>Risoluzione</th>
                        </tr>
                      </thead>
                      <tbody>
                        { evaluation && evaluation.assets && evaluation.assets.length ? evaluation.assets.map((ass) => 
                          <tr>
                            <td>{ ass.parcel_type ? ass.parcel_type.sigla : '-' }</td>
                            <td>{ ass.foglio }</td>
                            <td>{ ass.mappale }</td>
                            <td>{ ass.subalterno }</td>
                            <td style={{'paddingLeft':'100px'}}>
                              <input {...register(`differences[difformita][_${ass.id}]`)} type="radio" value="1" className='noMargin' onClick={() => handleResolutionBtnState(ass.id, '1')} /> Sì &nbsp;&nbsp;&nbsp;
                              <input {...register(`differences[difformita][_${ass.id}]`)} type="radio" value="0" className='noMargin' onClick={() => handleResolutionBtnState(ass.id, '0')} /> No
                            </td>
                            <td>
                              <select className='noMargin' {...register(`differences[inspection_difference_id][_${ass.id}]`)}>
                                <option value="">- Tipo difformità -</option>
                                { diffTypes && diffTypes.length ? diffTypes.map((type) => 
                                  <option key={type.id} value={type.id} selected={ass.inspection_difference_id == type.id}>{type.descrizione}</option>
                                ) : '' }
                              </select>
                            </td>
                            <td className="actions">
                              <Button title="Vedi o modifica risoluzione" onClick={() => { handleResolution(ass.id) }} className={ diffResBtnArray['_'+ass.id] !== '1' ? 'disabled' : '' }><DynamicIcon iconName="BiSolidPencil" /></Button>
                            </td>
                          </tr>
                        ) : '' }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </fieldset>
              {/* STEP 4 - QUESTIONARIO FINALE E RIEPILOGO */}
              <fieldset className={step == 4 ? 'wizard_step_content reg visible' : 'wizard_step_content reg'}>
                <Row><Col><label className='step_label'>QUESTIONARIO FINALE E RIEPILOGO</label></Col></Row>
                <Row>
                  <Col>
                    <label className='step_label'>QUESTIONARIO FINALE</label>
                    <Row style={{'marginBottom':'15px'}}>
                      <Col>
                        <label>Hai avuto accesso a tutti i beni indicati come quelli da periziare?</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div style={{'display':'none'}}>
                          <input {...register("inspection[questionario][risposta_1]")} type="radio" value="1" className='noMargin' /> Sì &nbsp;&nbsp;&nbsp;
                          <input {...register("inspection[questionario][risposta_1]")} type="radio" value="0" className='noMargin' /> No
                        </div>
                        { evaluation && evaluation.inspection && JSON.parse(evaluation.inspection.questionario)[0] == '1' ? 'Sì' : 'No' }
                      </Col>
                    </Row>
                    <Row style={{'marginBottom':'15px','marginTop':'25px'}}>
                      <Col>
                        <label>Hai potuto fare le foto a tutti gli ambienti?</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div style={{'display':'none'}}>
                          <input {...register("inspection[questionario][risposta_2]")} type="radio" value="1" className='noMargin' /> Sì &nbsp;&nbsp;&nbsp;
                          <input {...register("inspection[questionario][risposta_2]")} type="radio" value="0" className='noMargin' /> No
                        </div>
                        { evaluation && evaluation.inspection && JSON.parse(evaluation.inspection.questionario)[1] == '1' ? 'Sì' : 'No' }
                      </Col>
                    </Row>
                    <Row style={{'marginBottom':'15px','marginTop':'25px'}}>
                      <Col>
                        <label>Hai potuto prendere le misure reali?</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div style={{'display':'none'}}>
                          <input {...register("inspection[questionario][risposta_3]")} type="radio" value="1" className='noMargin' /> Sì &nbsp;&nbsp;&nbsp;
                          <input {...register("inspection[questionario][risposta_3]")} type="radio" value="0" className='noMargin' /> No
                        </div>
                        { evaluation && evaluation.inspection && JSON.parse(evaluation.inspection.questionario)[2] == '1' ? 'Sì' : 'No' }
                      </Col>
                    </Row>
                    <Row style={{'marginBottom':'15px','marginTop':'25px'}}>
                      <Col>
                        <label>Note del tecnico</label>
                        <p>{ evaluation && evaluation.inspection ? evaluation.inspection.note : '-' }</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <div className='inner_wrapper'>
                      <label className='step_label'>RIEPILOGO SOSTITUZIONI E MODIFICHE</label>
                      <Row>
                        <Col>
                          <Row>
                            <Col>
                              <label>Coordinate</label>
                              <p>{ evaluation && evaluation.inspection ? evaluation.inspection.lat : '-' },<br />{ evaluation && evaluation.inspection ? evaluation.inspection.lng : '-' }</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label>Rilevamenti fotografici</label>
                              <p>{ inspectionFotoFiles && inspectionFotoFiles.length ? inspectionFotoFiles.filter(f => !f.user || f.user.role.slug !== "qual-cont").length : '-' } caricamenti</p>
                            </Col>
                            <Col>
                              <label>&nbsp;</label>
                              <p className='added'>{ inspectionFotoFiles && inspectionFotoFiles.length ? inspectionFotoFiles.filter(f => f.user && f.user.role.slug === "qual-cont").length : '-' } aggiunte</p>
                              { inspectionFotoFiles && inspectionFotoFiles.length ? inspectionFotoFiles.filter(f => f.user && f.user.role && f.user.role.slug === "qual-cont").map((f, index) => {
                                let fileName = f.nome;
                                if (fileName.length > 25) {
                                  const extension = fileName.slice(-3);
                                  fileName = `${fileName.slice(0, 25)}...${extension}`;
                                }
                                return <p className='added small' key={index}>{fileName}</p>;
                              }) : '' }
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <label>Rilievi interni caricati</label>
                              <p>{ inspectionDiffFiles && inspectionDiffFiles.length ? inspectionDiffFiles.filter(f => !f.user || f.user.role.slug !== "qual-cont").length : '-' } documenti</p>
                            </Col>
                            <Col>
                              <label>&nbsp;</label>
                              <p className='added'>{ inspectionDiffFiles && inspectionDiffFiles.length ? inspectionDiffFiles.filter(f => f.user && f.user.role.slug === "qual-cont").length : '-' } aggiunte</p>
                              { inspectionDiffFiles && inspectionDiffFiles.length ? inspectionDiffFiles.filter(f => f.user && f.user.role && f.user.role.slug === "qual-cont").map((f, index) => {
                                let fileName = f.nome;
                                if (fileName.length > 25) {
                                  const extension = fileName.slice(-3);
                                  fileName = `${fileName.slice(0, 25)}...${extension}`;
                                }
                                return <p className='added small' key={index}>{fileName}</p>;
                              }) : '' }
                            </Col>
                          </Row>
                          <Row style={{'marginTop':'20px'}}>
                            <Col>
                              <label>Difformità riscontrate</label>
                              <p>{ evaluation && evaluation.inspection ? (evaluation.inspection.difformita == 1 ? 'Sì' : 'No') : '-' }</p>
                            </Col>
                            <Col>
                              <label>Data controllo</label>
                              <p>{ evaluation && evaluation.appointment ? evaluation.appointment.data_appuntamento : '-' }</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row style={{'marginTop':'40px'}}>
                    <Col>
                      <label className='step_label'>Aggiungi nota (facoltativo)</label>
                      <textarea placeholder="Inserisci qui la tua nota" {...register("inspection[note_qualita_1]")}></textarea>
                    </Col>
                  </Row>
              </fieldset>
            </form>
          </Col>
          <Col className='sidebar' style={{'paddingTop':'60px'}}>
            <Button className='sidebar_action suspend' onClick={() => { handleSuspension(state.evaluation_id) }}>Sospendi pratica</Button>
            <h2 style={{'marginTop':'40px'}}>Fasi controllo</h2>
            <div id="wizard_bar" className="vert">
              <div className={step >= 1 ? 'step active' : 'step'}>Coordinate e dati catastali</div>
              <div className={step >= 2 ? 'step active' : 'step'}>Rilevamenti fotografici</div>
              <div className={step >= 3 ? 'step active' : 'step'}>Scheda catastale e difformità</div>
              <div className={step >= 4 ? 'step active' : 'step'}>Questionario finale e riepilogo</div>
            </div>
            <h2 style={{'marginTop':'40px'}}>Dati tecnico</h2>
            <label>Nome e cognome</label>
            <p>{ evaluation && evaluation.technician ? evaluation.technician.nome+' '+evaluation.technician.cognome : '' }</p>
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            { step > 1 ?
              <Button onClick={() => goToStep(step-1)}>
                <DynamicIcon iconName='IoIosArrowBack' /> Indietro
              </Button>
            : '' }
            { step < 4 ?
              <Button className='inverse' onClick={() => goToStep(step+1)}>
                <DynamicIcon iconName='IoIosArrowForward' /> Continua
              </Button>
            : '' }
            { step == 4 ?
              <Button className='inverse' onClick={() => goToStep(999)}>
                <DynamicIcon iconName='IoIosArrowForward' /> Fine controllo
              </Button>
            : '' }
          </Col>
        </Row>
        <br /><hr />
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => { navigate('/evaluations', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) }}); }}>
              <DynamicIcon iconName='IoIosArrowBack' /> Torna alle pratiche
            </Button> 
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EvaluationsCon;