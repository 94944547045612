import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// TOLTIP
import { Tooltip } from 'react-tooltip';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function Alerts() {
  // AUTH, STATE, NAVIGATION
  const { authobj, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [alerts, setAlerts] = useState(null);

  useEffect(() => {
    retrieveAlerts();
  }, []);

  const retrieveAlerts = () => {
    axios.post(window.SERVER_URL+'alerts/list', { }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setAlerts(res.data.alerts);
    });
  }

  const handleRead = (alert_id) => {
    axios.post(window.SERVER_URL+'alerts/set_read', { id: alert_id }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      if (res.data.success === true) {
        retrieveAlerts();
        setGlobalRefresh();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Impossibile settare l'alert come letto"
        });
      }
    });
  }
  
  return (
    <>
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Alerts</h1>
          </Col>
        </Row>
        <Row>
          <Col className='tab_wrapper'>
            { alerts ? 
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Messaggio</th>
                      <th style={{'width':'120px'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    { alerts.length ? alerts.map((alert) => {
                        return <tr key={ alert.id } className={ alert.letto == 0 ? 'unread' : '' }>
                                <td style={{'width':'150px'}}>{ alert.created_at ? alert.created_at.substr(0, 10).split("-").reverse().join("/") : '-' }</td>
                                <td>{ alert.testo }</td>
                                <td className="actions">
                                  { alert.letto == 0 ?
                                    <Button onClick={() => { handleRead(alert.id) }} className='alert_read'>
                                      <DynamicIcon iconName='FaCheckCircle' />
                                      <Tooltip anchorSelect=".alert_read" place="top" variant="info">Segna come letto</Tooltip>
                                    </Button>
                                  : '' }
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
              </>
            : <p style={{textAlign:'center'}}>Nessun alert presente</p> }
          </Col>
        </Row>
      </Container>
    </>
  ); 
}

export default Alerts;