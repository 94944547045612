import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function UsersAdd() {
  // AUTH, STATE, NAVIGATION
  const { authobj } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);
  // DATA
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [zone, setZone] = useState(null);
  const [selectedZones, setSelectedZones] = useState([]);
  const [allZones, setAllZones] = useState({});
  // SPINNER
  const [wait, setWait] = useState(false);
  const [waitPercent, setWaitPercent] = useState(null);

  useEffect(() => {
    setWait(true);

    // Carico le province
    axios.post(window.SERVER_URL+'provinces/list', { }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      setProvinces(res.data.provinces);

      const updatedZones = {};
      res.data.provinces.forEach((prov) => {
        updatedZones[prov.id] = prov.nome;
      });
      setAllZones(updatedZones);
    
      axios.post(window.SERVER_URL+'roles/list', { technicians: false }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data.roles);
        setRoles(res.data.roles);

        // Se sto modificando una utente esistente, carico le informazioni relative
        if (state && state.user_id) {
          axios.post(window.SERVER_URL+'users/get', { id: state.user_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            console.log(res.data);
            let user_data = res.data;
            setUser(res.data);          

            // Setto i campi della form
            let fields = {};
            for (const [key, val] of Object.entries(user_data.user))
              if (typeof val !== 'object')
                fields[key] = val;
            for (const [key, val] of Object.entries(user_data.user.role))
              if (typeof val !== 'object')
                fields[key] = val;
            for (const [key, val] of Object.entries(user_data.user.profile))
              if (typeof val !== 'object')
                fields[key] = val;
            fields['password'] = '**********';
            reset(fields);

            if (user_data && user_data.user)
              setSelectedZones(user_data.user.zone);

            setWait(false);
          });
        } else {
          setWait(false);
        }
      });
    });
  }, [reset]);

  const handleSelectZone = (event) => {
    let prov_id = event.target.value;
    let prov_name = event.target.options[event.target.selectedIndex].text;
    setZone(prov_id);
  }

  const handleAddZone = () => {
    if (zone) {
      setSelectedZones((prevZones) => prevZones.includes(zone) ? prevZones : [...prevZones, zone]);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Devi selezionare una provincia per aggiungerla alla lista"
      });
    }
  }

  const handleRemoveZone = (prov_id) => {
    setSelectedZones((prevSelectedZones) => 
      prevSelectedZones.filter(id => id !== prov_id)
    );
  }
  
  // Submit della form
  const onSubmit = (form_data) => {
    console.log(form_data);
    var dataForm = {};
    dataForm['users'] = {
      'id': (state.user_id ? state.user_id : null),
      'role_id': form_data.role_id,
      'email': form_data.email,
      'password': form_data.password,
      'nome': form_data.nome,
      'cognome': form_data.cognome,
      'zone': JSON.stringify(selectedZones ? selectedZones: [])
    };
    dataForm['profiles'] = {
      'telefono': form_data.telefono,
      'cellulare': form_data.cellulare,
    };
    axios.post(window.SERVER_URL+'users/save', dataForm, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token,
        // 'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Utente salvato',
          text: 'Utente salvato correttamente'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/users');
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non è stato possibile salvare l'utente"
        });
      }
    }).catch(function(error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Non è stato possibile salvare l'utente"
      });
    });
  }

  return (
    <>
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Container className="content">
        <Row className="page_title">
          <Col>
            <h1>Aggiungi o modifica utente</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom_form_wrapper">
            <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
              <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px'}}>
                <label className="fieldset_label"><DynamicIcon iconName="FaUser" size="20" spaced /> INFORMAZIONI ANAGRAFICHE</label>
                <Row>
                  <Col>
                    <label>Nome</label>
                    <input type="text" placeholder="Nome" {...register("nome", { required: true })} />
                    {errors.nome && <span>Devi inserire un nome</span>}
                  </Col>
                  <Col>
                    <label>Cognome</label>
                    <input type="text" placeholder="Cognome" {...register("cognome", { required: true })} />
                    {errors.cognome && <span>Devi inserire un cognome</span>}
                  </Col>
                  <Col>
                    <label>Ruolo</label>
                    <select {...register("role_id", { required: true })}>
                      <option value="">- Seleziona un ruolo -</option>
                      { roles && roles.length ? roles.map(role => 
                        <option key={ role.id } value={ role.id }>{ role.descrizione }</option>
                      ) : ''}
                    </select>
                    {errors.role_id && <span>Devi scegliere un ruolo</span>}
                  </Col>
                </Row>
              </fieldset>
              <fieldset style={{'background':'#eee','padding':'5px 20px 20px','marginTop':'20px'}}>
                <label className="fieldset_label"><DynamicIcon iconName="BsTelephoneFill" size="20" spaced /> INFORMAZIONI DI CONTATTO</label>
                <Row>
                  <Col>
                    <label>Telefono</label>
                    <input type="text" placeholder="Telefono" {...register("telefono", { required: true })} />
                  </Col>
                  <Col>
                    <label>Cellulare</label>
                    <input type="text" placeholder="Cellulare" {...register("cellulare", { required: true })} />
                  </Col>
                </Row>
              </fieldset>
              <fieldset style={{'background':'#f8f8f8','padding':'5px 20px 20px','marginTop':'20px'}}>
                <label className="fieldset_label"><DynamicIcon iconName="FaKey" size="20" spaced /> INFORMAZIONI DI ACCESSO</label>
                <Row>
                  <Col>
                    <label>E-mail</label>
                    <input type="email" placeholder="E-mail" {...register("email", { required: true })} />
                    {errors.email && <span>Devi inserire un indirizzo e-mail</span>}
                  </Col>
                  <Col>
                    <label>Password</label>
                    <input type="password" placeholder="Password" {...register("password", { required: true })} />
                    {errors.password && <span>Devi inserire una password</span>}
                  </Col>
                </Row>
              </fieldset>
              { user && user.user && user.user.role && ['qual-cont','qual-val'].includes(user.user.role.slug) ? 
                <fieldset style={{'background':'#eee','padding':'5px 20px 20px','marginTop':'20px'}}>
                  <label className="fieldset_label"><DynamicIcon iconName="FaMapMarkerAlt" size="20" spaced /> COMPETENZA GEOGRAFICA</label>
                  <Row>
                    <Col>
                        <br /><p><b>Aggiungi tutte le province in cui questo utente può lavorare.</b></p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Provincia</label>
                      <select style={{'width':'300px','display':'inline-block','margin':'0 15px'}} onChange={handleSelectZone}>
                        <option value="">- Seleziona la provincia -</option>
                        { provinces ? provinces.map((prov) => 
                          <option key={ 'GEO'+prov.id } value={ prov.id }>{ prov.nome }</option>
                        ) : '' }
                      </select>
                      <Button onClick={() => { handleAddZone() }}>Aggiungi</Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                        <ul className='tag_list'>
                        { selectedZones && selectedZones.length ? selectedZones.map(prov_id =>
                          <li>
                            { allZones[prov_id] }
                            <span onClick={() => handleRemoveZone(prov_id)}><DynamicIcon iconName="TiDelete" size="20" /></span>
                          </li>
                        ) : '' }
                        </ul>
                    </Col>
                  </Row>
                </fieldset>
              : '' }
              <Button type="submit" variant="primary" className="waste_btn">Salva</Button>
            </form>
          </Col>
        </Row>
        <Row className="action_bar bottom">
          <Col>
            <Button onClick={() => navigate('/users', { state: { page: (state && state.page ? state.page : 1), query: (state && state.query ? state.query : null) } })}><DynamicIcon iconName='IoIosArrowBack' /> Torna agli utenti</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
  
}

export default UsersAdd;